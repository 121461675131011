@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './scorecardWidgets.scss';
@import url('//fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

// @font-face {
//   font-family: 'Montserrat';
//   src: local('Montserrat'), url('../fonts/Montserrat-Regular.ttf') format('truetype');
// }

div,
span,
h1,
h2,
p,
ion-label {
  font-family: 'Montserrat' !important;
}

/* Backdrop styles */
.MuiBackdrop-root {
  background: unset;
  opacity: 0.7;
}

.multi-add-icon {
  height: 25px;
  cursor: pointer;
}

.multi-remove-icon {
  height: 30px;
  cursor: pointer;
}

/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 4px;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;
  font-family: var(--headers-font-family);
  font-size: 14px;
  height: 48px;
  padding: 0;

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;
    font-family: var(--headers-font-family);
    padding: 14px 16px;
  }

  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

.input-label {
  font-family: var(--headers-font-family);
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

// Modal styles
.modal-title {
  .MuiTypography-root {
    font-family: var(--headers-font-family) !important;
    font-size: 32px !important;
    color: #ffffff;
  }
}

.modal-content-ctn {
  background-color: #ffffff;
  background: #ffffff !important;

  .date-control,
  .input-control {
    border: 1px solid var(--filter-controls-border);
  }
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 40px !important;
}
.hand-pt {
  cursor: pointer;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}

.MuiTab-root {
  text-transform: none;
}

.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  height: 100%;
  z-index: 1000;
}

.sticky-sidebar {
  /* Safari */
  position: fixed;
  height: 100%;
  z-index: 1000;
  // top: 5px;
  width: 13vw;
}

.b-text {
  font-weight: 600;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success);
}

.loss {
  color: var(--ion-color-danger);
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

.game-rules-drawer {
  .MuiDrawer-paper {
    background-color: var(--ion-background-color) !important;
    min-width: 450px;
    @media only screen and (max-width: 400px) {
      min-width: 100%;
    }
  }

  &.mob-view {
    .MuiDrawer-paper {
      min-width: 100vw;
      .odds-terms-condi-ctn {
        padding: 10px 16px 0 16px;
        .MuiAccordion-root .MuiAccordionSummary-root {
          background: var(--table-header-background) !important;
          .MuiTypography-root {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.Toastify__toast-body {
  div {
    display: flex;
    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }
}
.MuiAccordion-root::before {
  height: 0 !important;
}

@keyframes color-change {
  0% {
    color: red;
    font-weight: bolder;
  }

  50% {
    color: var(--ion-color-success);
    font-weight: 600;
  }

  100% {
    color: var(--ion-color-primary);
    font-weight: bolder;
  }
}

@keyframes cup-color-change {
  0% {
    color: rgb(255, 255, 255);
    font-weight: bolder;
  }

  100% {
    color: var(--ion-color-primary);
    font-weight: bolder;
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }

  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    color: red;
    transform: scale(1);
  }

  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

//Disable step buttons of number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.sc-ion-select-popover-ios {
  color: white !important;
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

ion-select-option {
  color: white !important;
}

.text-light {
  --color: var(--ion-text-light);
  --placeholder-color: var(--ion-text-light);
  color: var(--ion-text-light);
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  text-transform: capitalize;
  color: var(--ion-text-light);
  line-height: 21px;
}

/* Chrome, Safari, Edge, Opera */
input.sc-ion-input-md::-webkit-outer-spin-button,
input.sc-ion-input-md::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}

.password-dialog-ctn {
  &.MuiDialog-root
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogContent-root {
    padding: 0 !important;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.theme-drop-down {
  border-radius: 0px;
  border: 3px solid var(--ion-color-primary) !important;
  padding: 5px;
  align-items: center;

  background-color: #fff;
  .theme-options1 {
    align-items: center;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    .circle1 {
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: var(--table-head-bg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 0px !important;

      .label {
        font-size: 8px;
        color: #000;
        font-weight: bolder;
      }
      .round {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        .sport-icon2 {
          height: 32px;
          width: 32px;
        }
        .sport-icon {
          height: 22px;
          width: 25px;
        }
        .sport-icon1 {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .theme-options {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    .circle1 {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      background: var(--common-dark);
      border: 1px solid var(--filter-border) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
      cursor: pointer;
      .round1 {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-primary);
        .moon-img {
          color: var(--text-primary);
          fill: var(--text-primary);
        }
      }
      .round {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        &.bg1 {
          background: linear-gradient(
            90deg,
            var(--th1-primary),
            var(--th1-primary) 49%,
            white 49%,
            white 51%,
            var(--th1-bg) 51%
          );
        }
        &.bg2 {
          background: linear-gradient(
            90deg,
            var(--th2-primary),
            var(--th2-primary) 49%,
            white 49%,
            white 51%,
            var(--th2-bg) 51%
          );
        }
        &.bg3 {
          background: linear-gradient(
            90deg,
            var(--th3-primary),
            var(--th3-primary) 49%,
            white 49%,
            white 51%,
            var(--th3-bg) 51%
          );
        }
        &.bg4 {
          background: linear-gradient(
            90deg,
            var(--th4-primary),
            var(--th4-primary) 49%,
            white 49%,
            white 51%,
            var(--th4-bg) 51%
          );
        }
        &.bg5 {
          background: linear-gradient(
            90deg,
            var(--th5-primary),
            var(--th5-primary) 49%,
            white 49%,
            white 51%,
            var(--th5-bg) 51%
          );
        }
        &.bg6 {
          background: linear-gradient(
            90deg,
            var(--th6-primary),
            var(--th6-primary) 49%,
            white 49%,
            white 51%,
            var(--th6-bg) 51%
          );
        }
        &.bg7 {
          background: linear-gradient(
            90deg,
            var(--th7-primary),
            var(--th7-primary) 49%,
            white 49%,
            white 51%,
            var(--th7-bg) 51%
          );
        }
        &.bg8 {
          background: linear-gradient(
            90deg,
            var(--th8-primary),
            var(--th8-primary) 49%,
            white 49%,
            white 49%,
            white 51%,
            var(--th8-bg) 51%
          );
        }
        &.bg9 {
          background: linear-gradient(
            90deg,
            var(--th9-primary),
            var(--th9-primary) 49%,
            white 49%,
            white 51%,
            var(--th9-bg) 51%
          );
        }
        &.bg10 {
          background: linear-gradient(
            90deg,
            var(--th10-primary),
            var(--th10-primary) 49%,
            white 49%,
            white 51%,
            var(--th10-bg) 51%
          );
        }
      }
    }
  }
}
