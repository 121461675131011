.login-form-page {
  .bottom-link {
    color: var(--ion-text-color);
    text-align: center;
    padding-top: 10px;
    text-decoration: underline;
    font-weight: 500;
    font-family: var(--headers-font-family);
  }

  .red-text {
    color: red;
  }

  .title-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .logo {
      width: 350px;
    }
  }

  .login-form-ctn {
    // margin-top: 4rem;
    min-width: 900px;
    max-height: unset;
    display: flex;
    // padding: 25px 35px 40px 35px;
    flex-direction: column;
    background-color: var(--ion-color-primary);
    border-radius: 30px;
    .login-container {
      display: flex;
      @media screen and (max-width: 720px) {
        flex-direction: column;
      }
      .left-container {
        display: flex;
        flex-direction: column;
        background: var(--ion-color-primary);
        width: 50%;
        @media screen and (max-width: 720px) {
          flex-direction: row;
          width: 100%;
          margin-bottom: 25px;
          border-radius: 30px 30px 0px 0px;
        }
        .login-text {
          color: #fff;
          text-align: center;
          font-family: var(--headers-font-family);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          margin-top: 30px;
          padding: 2px 10px 2px 10px;
          @media screen and (max-width: 720px) {
            font-size: 12px;
            padding: 25px 5px 0px 5px;
            margin-bottom: -20px;
            margin-top: 50px;
          }
        }
      }
      .right-container {
        background-color: #000;
        width: 50%;
        @media screen and (max-width: 720px) {
          width: 100%;
        }
        .login-fig {
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 720px) {
            justify-content: space-between;
          }

          .login-picture {
            padding-top: 10px;
            color: var(--ion-color-primary);
            width: 140px;
            height: 70px;
            padding-left: 64px;
            @media screen and (max-width: 720px) {
              padding: 10px 0px 0px 72px;
              width: 140px;
              height: 70px;
            }
          }
          .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 50px 50px;
            border-color: transparent transparent var(--ion-color-primary)
              transparent;
            transform: rotate(270deg);
            @media screen and (max-width: 720px) {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 50px 50px;
              border-color: transparent transparent var(--ion-color-primary)
                transparent;
              transform: rotate(270deg);
            }
          }
        }
      }
    }

    .card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 54px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 20px;
      color: #fff;
      display: flex;
      justify-content: center;
    }

    .sign-up-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 50px;

      .sign-up-color {
        color: var(--ion-color-primary);
        text-decoration: underline;
      }
    }

    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: var(--ion-color-secondary-contrast);
      font-family: var(--headers-font-family);
    }

    .fields-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0px 25px;
      @media screen and (max-width: 720px) {
        display: flex;
        flex-direction: column;
      }

      .usr-input,
      .code-input,
      .pwd-input {
        display: inline-grid;

        .input-labell {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          margin-bottom: 5px;
          color: #fff !important;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
    .pwd-input {
      margin-top: 25px;
    }

    .form-control {
      margin: 10px 0;

      .MuiFormControl-root {
        background: #1f2e52 !important;

        .MuiInputBase-root {
          background: #1f2e52 !important;
          color: #fff !important;
          border-radius: 10px;
        }
      }
    }

    .MuiInputBase-root {
      border-radius: 10px;
      background-color: #eaeef3 !important;
      border: 0;
    }

    .MuiInputBase-input {
      font-size: 16px;
      font-family: var(--headers-font-family);
      background: #eaeef3;
      border: 1px solid #eaeef3;
      border-radius: 10px;
      border: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    .MuiIconButton-root {
      padding: 0;
      opacity: 0.4;
    }

    .login-err-msg {
      color: red;
      margin-top: 20px;
      text-align: center;
      max-width: unset;
    }

    .user-name,
    .pwd-field {
      max-width: 100%;
      height: 48px;

      .input-label {
        color: #fff !important;
      }
    }

    .login-demologin-btns {
      display: flex;
      justify-content: space-between;
      .login-form-btn {
        margin-top: 40px !important;
        margin-bottom: 20px;
        width: 48%;
        height: 50px;
        font-size: 13px;
        border-radius: 50px;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast) !important;
        font-weight: bold;

        .MuiButton-label {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          text-transform: capitalize;

          @media screen and (min-width: 320px) and (max-width: 400px) {
            font-size: 12px !important;
          }
        }
      }
    }

    .login-demologin-btns {
      display: flex;
      justify-content: space-around;
      .login-form-btn-without-demologin {
        margin-top: 40px !important;
        margin-bottom: 20px;
        width: 50%;
        height: 50px;
        font-size: 13px;
        border-radius: 50px;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast) !important;
        font-weight: bold;
        .MuiButton-label {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          text-transform: capitalize;
          color: #fff;
        }
      }
    }

    .forgot-pwd {
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    .bottom-link {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 16px;
      color: var(--ion-color-secondary-contrast);
      text-align: center;
      text-decoration: underline;
      cursor: pointer;

      .register-link {
        color: var(--ion-color-secondary-contrast);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-form-page .login-form-ctn .user-name,
  .login-form-page .login-form-ctn .pwd-field {
    height: 40px;

    .MuiInputBase-root {
      height: 40px;

      .MuiInputBase-input {
        // height: 40px;
        padding: 10px 16px;
      }
    }
  }

  .login-ctn .title-row .logo {
    max-width: 220px !important;
    min-height: unset !important;
  }

  .login-form-page {
    width: 100vw;
    padding: 0px 16px;

    .bottom-link {
      margin-top: 8px;
      text-decoration: underline;
    }

    .login-form-ctn {
      .forgot-pwd-link {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .logo {
        width: 200px;
      }
    }

    .login-form-ctn {
      min-width: 80% !important;
      max-width: unset !important;
      margin-top: 4rem;
      // padding: 20px 18px !important;

      .login-form-btn {
        margin-top: 20px;
        height: 44px;
        border-radius: 8px;

        .MuiButton-label {
          font-size: 16px;
        }
      }

      .card-title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 24px;
        margin-top: 5px;
      }

      .sign-up-title {
        font-size: 14px;
        margin-bottom: 34px;
      }

      .usr-input,
      .pwd-input {
        .input-labell {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (device-width: 360px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (device-width: 411px) and (device-height: 823px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;
    }
  }
}

@media only screen and (device-width: 375px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
    }
  }
}

@media only screen and (max-device-width: 320px) {
  .login-form-page {
    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 250px !important;
      max-width: 250px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: var(--headers-font-family);
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }

      // .user-name,
      // .pwd-field,
      // .login-form-btn {
      //   max-width: 250px;
      // }

      .login-form-btn {
        height: 30px;
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-device-width: 1023px) and (min-device-width: 769px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 550px !important;
      max-width: 550px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: var(--headers-font-family);
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }

      // .user-name,
      // .pwd-field,
      // .login-form-btn {
      //   max-width: 250px;
      // }

      .login-form-btn {
        height: 60px;
        font-size: 5px;
      }
    }
  }
}

@media only screen and (max-device-width: 440px) {
  .login-form-page {
    .login-form-ctn {
      .login-demologin-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        .login-form-btn {
          width: 100%;
          height: 35px;
          margin: 20px 12px 10px 13px !important;
          margin-bottom: 10px !important;
          border-radius: 50px;
          .MuiButton-label {
            color: var(--ion-text-light);
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 400px) and (min-width: 320px) {
  .login-form-page .login-form-ctn .login-demologin-btns .login-form-btn {
    width: 123px !important;
    min-width: 120px !important;
  }
}
