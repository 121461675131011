/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* Gradient styles */
  --gradient-color-primary: #045662;
  --gradient-color-secondary: #045662;

  /** primary **/
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--ion-color-primary) 3.56%,
    var(--ion-color-primary) 97.13%
  );
  --ion-color-primary: #045662;
  --ion-color-primary-rgb: 255, 167, 36;
  --ion-color-primary-contrast: #0a0d15;
  --ion-color-primary-contrast-rgb: 10, 13, 21;
  --ion-color-primary-shade: #e09320;
  --ion-color-primary-tint: #ffb03a;
  --ion-primary-border-color: #045662;
  --background-ctn-gradient: #fff;
  --markets-table-background: #eff4ff;
  /** secondary **/
  --ion-color-secondary: #37446e;
  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #303c61;
  --ion-color-secondary-tint: #4b577d;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #34a300;
  --ion-color-success-rgb: 52, 163, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e05656;
  --ion-color-danger-tint: #ff7272;
  --ion-color-lost: #cf0a13;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-limits: #000;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Sports List Navbar Colors **/
  --ion-nav-card-background: var(--ion-color-primary);
  --ion-nav-card-text: #00143d;

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;
  /* --ion-header-background: #00143d; */
  --ion-header-link-color: #fff;

  /** Back and Lay **/
  --back-odd-background: #6bc0ff;
  --back-odd-background-light: #89bce2;
  --back-odd-background-rgb: 151, 211, 255;
  --lay-odd-background: #ff8282;
  --lay-odd-background-rgb: 255, 173, 173;
  --lay-odd-background-light: rgba(255, 130, 130, 0.5);
  --premium-odd-background: #72e3a0;
  --premium-tab-color: #fff;

  /** Table colour **/
  --table-headers-light: rgba(32, 32, 32, 0.6);
  --headers-font-family: 'Montserrat';

  /* Odds info icons */
  --market-info-icons: #fff;

  /*
   * White Label variables
   * ---------------------
   */
  --odd-btn-animation-color: #ffa724;
  --footer-text-color: #6a7394;
  --nav-list-live-icon-color: #ff0000;

  /** Header Logo Sizes **/
  --logo-xl-width: 174px;
  --logo-xl-height: 62px;
  --logo-lg-width: 176px;
  --logo-lg-height: 53px;
  --logo-md-width: 96px;
  --logo-md-height: 24px;
  --logo-sm-width: 130px;
  --logo-sm-height: 50px;
  --logo-xs-width: 146px;
  --logo-xs-height: 53px;

  /* Login Page logo */
  --login-logo-xl-width: 320px;
  --login-logo-xl-height: 80px;
  --login-logo-sm-width: 156px;
  --login-logo-sm-height: 68px;

  /* Sidebar links */
  --sidebar-active-link-color: #045662;

  /* Dashboard colors */
  --dashboard-ctn-background: #f9f9f9;
  --dashboard-provider-background: #045662;
  --dashboard-copyright-background: #ededed;
  --dashboard-copyright-text-color: #575757;
  --dashboard-text-border-btm: linear-gradient(
    90deg,
    #ffa724 0%,
    rgba(255, 167, 36, 0) 100%
  );
  --dashboard-live-game-dc-card-ctn: var(--ion-color-primary);
  --linear-color-change: #ffa724;
  --home-unerline-linear-color-change: #ffa724;
  --events-table-broder-col: #045662;
  --enabled-market-btn-col: #045662;

  /* Footer Background */
  --footer-ctn-background: #0d2557;
  --footer-vertical-gradient: linear-gradient(180deg, #0d2456 0%, #07132c 100%);

  --th1-primary: #045662;
  --th1-bg: #fff;

  --th2-primary: #92148a;
  --th2-bg: #fff;

  --th3-primary: #fcd535;
  --th3-bg: #fff;

  --th4-primary: #ff6300;
  --th4-bg: #fff;

  --th5-primary: #00bdfe;
  --th5-bg: #fff;

  --th6-primary: #045662;
  --th6-bg: #272727;

  --th7-primary: #92148a;
  --th7-bg: #272727;

  --th8-primary: #fcd535;
  --th8-bg: #272727;

  --th9-primary: #ff6300;
  --th9-bg: #272727;

  --th10-primary: #00bdfe;
  --th10-bg: #272727;
}
/*
 * Light Colors
 * ------------------
 */
.ios body,
.md body {
  --ion-background: #f9f9f9;
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-color: #fff;
  --common-dark: #000;
  --ion-header-background: #045662;
  --ion-subheader-background: #444;
  --ion-background-secondary: #072667;
  --ion-background-tertiary: #202d57;
  --ion-home-heading-color: #045662;
  --ion-home-heading-bg: #045662;
  --linear-gradient-background-1: #045662;
  --linear-gradient-background-2: #045662;
  --accoridan-summary-background: #045662;
  --table-header-background: #045662;
  --webkit-gradient-background-1: #045662;
  --webkit-gradient-background-2: #045662;
  --ion-text-color: #00143d;
  --ion-text-color-rgb: 0, 20, 61;
  --ion-text-light: #ffffff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #000;
  --ion-text-dark-rgb: 10, 13, 21;
  --ion-text-color-contrast: var(--ion-text-dark);
  --ion-text-color-contrast-rgb: var(--ion-text-dark-rgb);
  /** Card Variables**/
  --ion-card-background: #e4e8ed;
  --ion-card-text-color: #00143d;
  --ion-card-background-contrast: #e2e2e2;
  --ion-card-background-table: #fff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /** Popover Variables**/
  --ion-popover-background: #045662;
  --ion-popover-dashboard-bg: #fff;
  --ion-popover-select-background: #ebebeb;
  --ion-popover--hover-background: #fff;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #ffffff;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #ffffff;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;
  --inplay-mob-bg: #ededed;

  /** IonSelect Styles **/
  --select-element-background: var(--ion-popover--hover-background);
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-popover--hover-background);
  --ion-select-background: #f0f0f0;

  /** Dialog Styles **/
  --dialog-header-background: #202d57;
  --dialog-content-background: #e4e8ed;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #ffffff;
  --date-picker-header-button-background: #e4e8ed;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #00143d;

  /** Input Field Styles **/
  --input-background-color: #072667;
  --input-text-color: #0a0d15;

  /** Reports style **/
  --reports-bg-color: #ffffff;
  --reports-title-color: #000;
  --filter-controls-border: #000;
  /* Odds info icons */
  --market-info-icons: #fff;

  /* Scorecard */
  --score-card-details-color: var(--ion-color-primary);
  --score-card-background-color: #141435;

  /* signup card */
  --signup-card-background-color: #fff;
  --signup-font-color: #000;

  /* notification background color */
  --notification-background-col: #e19627;

  /* Markets Accordian*/
  --accordian-headers-color: #fff;
  --ion-grid-background: #fff;

  --table-bg: #fff;
  --sidebar-btn-bg: #f9f9f9;
  --no-bets-bg: #eff4ff;
}

.ios body.dark,
.md body.dark {
  --ion-background: #101010;
  --ion-text-color: #fff;
  --ion-text-color-contrast: #fff;
  --ion-header-background: #045662;
  --ion-grid-background: linear-gradient(
    92deg,
    rgba(61, 61, 61, 0.27) 10.61%,
    rgba(39, 39, 39, 0.27) 97.95%
  );
  --background-ctn-gradient: linear-gradient(
    92deg,
    rgba(61, 61, 61, 0.27) 10.61%,
    rgba(39, 39, 39, 0.27) 97.95%
  );
  --dashboard-copyright-background: #272727;
  --table-bg: #272727;
  --ion-background-color: #272727;
  --no-bets-bg: #272727;
  --sidebar-btn-bg: #424242;
  --reports-title-color: #fff;
  --date-picker-container-background: #272727;
  --ion-color-dark-limits: #fff;
}

.ios body.green,
.md body.green {
  --ion-color-primary: #ff6300;
  --ion-header-background: #ff6300;
  --ion-home-heading-color: #ff6300;
  --ion-home-heading-bg: #ff6300;
  --linear-gradient-background-1: #ff6300;
  --linear-gradient-background-2: #ff6300;
  --accoridan-summary-background: #ff6300;
  --table-header-background: #ff6300;
  --webkit-gradient-background-1: #ff6300;
  --webkit-gradient-background-2: #ff6300;
  --dashboard-provider-background: #ff6300;
  --sidebar-active-link-color: #ff6300;
  --ion-primary-border-color: #ff6300;
  --enabled-market-btn-col: #ff6300;
  --dashboard-live-game-dc-card-ctn: var(--ion-color-primary);
  --select-popover-background: var(--ion-popover-background);
  --ion-popover-background: var(--ion-color-primary);
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--ion-color-primary) 3.56%,
    var(--ion-color-primary) 97.13%
  );
}

.ios body.yellow,
.md body.yellow {
  --ion-color-primary: #fcd535;
  --ion-header-background: #fcd535;
  --ion-home-heading-color: #fcd535;
  --ion-home-heading-bg: #fcd535;
  --linear-gradient-background-1: #fcd535;
  --linear-gradient-background-2: #fcd535;
  --accoridan-summary-background: #fcd535;
  --table-header-background: #fcd535;
  --webkit-gradient-background-1: #fcd535;
  --webkit-gradient-background-2: #fcd535;
  --dashboard-provider-background: #fcd535;
  --sidebar-active-link-color: #fcd535;
  --ion-primary-border-color: #fcd535;
  --enabled-market-btn-col: #fcd535;
  --dashboard-live-game-dc-card-ctn: var(--ion-color-primary);
  --ion-popover-background: var(--ion-color-primary);
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--ion-color-primary) 3.56%,
    var(--ion-color-primary) 97.13%
  );
}

.ios body.magenta,
.md body.magenta {
  --ion-color-primary: #92148a;
  --ion-header-background: #92148a;
  --ion-home-heading-color: #92148a;
  --ion-home-heading-bg: #92148a;
  --linear-gradient-background-1: #92148a;
  --linear-gradient-background-2: #92148a;
  --accoridan-summary-background: #92148a;
  --table-header-background: #92148a;
  --webkit-gradient-background-1: #92148a;
  --webkit-gradient-background-2: #92148a;
  --dashboard-provider-background: #92148a;
  --sidebar-active-link-color: #92148a;
  --ion-primary-border-color: #92148a;
  --enabled-market-btn-col: #92148a;
  --dashboard-live-game-dc-card-ctn: var(--ion-color-primary);
  --ion-popover-background: var(--ion-color-primary);
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--ion-color-primary) 3.56%,
    var(--ion-color-primary) 97.13%
  );
}

.ios body.blue,
.md body.blue {
  --ion-color-primary: #00bdfe;
  --ion-header-background: #00bdfe;
  --ion-home-heading-color: #00bdfe;
  --ion-home-heading-bg: #00bdfe;
  --linear-gradient-background-1: #00bdfe;
  --linear-gradient-background-2: #00bdfe;
  --accoridan-summary-background: #00bdfe;
  --table-header-background: #00bdfe;
  --webkit-gradient-background-1: #00bdfe;
  --webkit-gradient-background-2: #00bdfe;
  --dashboard-provider-background: #00bdfe;
  --sidebar-active-link-color: #00bdfe;
  --ion-primary-border-color: #00bdfe;
  --enabled-market-btn-col: #00bdfe;
  --dashboard-live-game-dc-card-ctn: var(--ion-color-primary);
  --ion-popover-background: #00bdfe;
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--ion-color-primary) 3.56%,
    var(--ion-color-primary) 97.13%
  );
}
/*
* Material Design Dark Theme
* -------------------------------------------
*/
/* .ios body.dark,
.md body.dark {
  --ion-header-background: #fff;
} */

/* .ios body.blue,
.md body.blue {
  --ion-header-background: #000;
} */

ion-title {
  --color: #ffffff;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
