.reports-ctn {
  border-radius: 4px;
  margin-right: 0px;
  margin-bottom: 30px;
  margin-top: 8px;
  background: var(--table-bg);
  border-radius: 30px;
  .header-ctn {
    ion-row {
      padding: 24px 24px 0px 24px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      @media screen and (max-width: 720px) {
        padding: 5px 24px 0px 10px;
      }
    }

    .page-title {
      color: var(--reports-title-color);
      font-family: var(--headers-font-family);
      font-style: normal;
      letter-spacing: 0.01em;
      float: left;
      margin-right: auto;
      margin-top: 5px;
      font-weight: 600;
      font-size: 24px;
      line-height: 99%;
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      @media screen and (min-width: 920px) {
        &.button-page-title {
          display: none;
        }
      }

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 4px;
        margin-top: -3px;
        path {
          fill: var(--ion-color-primary);
        }

        @media screen and (max-width: 920px) {
          width: 21px;
          height: 21px;
        }
      }
    }

    .img-page-title-row {
      display: inline-flex;
      align-items: center;
      line-height: 42px;
      margin-right: auto;

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 4px;
        margin-top: -3px;
        path {
          fill: var(--ion-color-primary);
        }
      }

      .title {
        margin-left: 4px;
        font-size: 24px;
        font-family: var(--headers-font-family) !important;
        color: var(--reports-title-color);
        font-weight: 600;
        font-size: 24px;
        line-height: 99%;
      }
    }
  }

  .filters-row {
    ion-select,
    .date-control {
      // border: 1px solid var(--filter-controls-border);
    }

    .filter-label {
      color: var(--ion-text-color);
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px;
      font-weight: 500;
    }

    .MuiFormControl-root,
    ion-select {
      max-height: 34px;
      max-width: 220px;
      background: var(--ion-card-background-contrast);
      border-radius: 30px;
      color: #000;
      @media screen and (max-width: 768px) {
        max-width: unset;
      }
    }
  }

  .content-ctn {
    background-color: var(--ion-card-background);
    border-radius: 6px;

    &.light-bg {
      background-color: var(--table-bg);
    }

    .tbl-ctn {
      //border-radius: 8px 8px 14px 14px;
      //border: 1px solid #D4D4D4;
      border-top: 0;
      background: none !important;

      .text-capitalize {
        text-transform: capitalize;
      }

      @media screen and (max-width: 768px) {
        border-radius: 10px 10px 10px;
      }

      .MuiTableContainer-root {
        .MuiTable-root {
          // background: var(--webkit-gradient-background-2);
          // background: var(--linear-gradient-background-2);

          .MuiTableHead-root {
            .MuiTableCell-head {
              color: #fff !important;
            }
          }

          .MuiTableBody-root {
            background-color: #fff;

            .MuiTableRow-root {
              background-color: var(--dashboard-copyright-background);
              // border-bottom: 1.5px solid #D9D9D9 !important;
            }

            // .MuiTableRow-root:nth-child(even) {
            //   background: #fff;
            // }
            // .MuiTableRow-root:nth-child(odd) {
            //   background: rgba(207, 207, 207, 0.3);
            // }
          }
        }
      }

      // .no-data-row {
      //   background: #fff !important;
      // }
    }

    .tbl-ctn:not(.no-hov-style) {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:hover {
              background-color: rgba(255, 167, 36, 0.2) !important;
            }
          }
        }
      }
    }

    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:nth-child(even) {
              // background: var(--ion-card-background-contrast);
            }

            .MuiTableRow-root:hover {
              background-color: rgba(255, 167, 36, 0.2) !important;
            }

            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }
            }

            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
          }
        }
      }
    }
  }

  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 2px solid #636c89;
      display: inline-flex;
      height: 48px;
      border-radius: 4px;

      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;

        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }
      }
    }
  }

  .no-data-row {
    background: #fff;
    width: 100%;
    //border-bottom: 0px !important;
    color: var(--ion-text-color-contrast);

    padding-left: 15px;
    font-family: var(--ion-font-family);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border-radius: 0 0 8px 8px;
    border: 1px solid #cdcdcd;

    @media screen and (max-width: 920px) {
      //border-radius: 0;
      font-size: 10px;
    }

    div {
      opacity: 0.5;
    }
  }

  .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
    color: var(--ion-text-color);
    font-family: var(--headers-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &.profit {
      color: var(--ion-color-success) !important;
    }

    &.loss {
      color: var(--ion-color-danger) !important;
    }
  }
}

.h-100 {
  height: 100%;
  // background: #f9f9f9;
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }

  .reports-ctn {
    margin: 10px 0px !important;

    .header-ctn,
    .content-ctn {
      padding: 0 8px;

      .points-type-toggle {
        border: 1px solid #636c89;
        height: 32px;
        border-radius: 4px;

        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }

      .filters,
      .filters-row {
        justify-content: center;
        margin-top: 4px;
        font-size: 12px;
        margin-bottom: 8px;
        ion-select,
        .MuiFormControl-marginNormal {
          height: 32px !important;
          background-color: var(--ion-card-background-contrast);
          border-radius: 30px;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }
      }
    }

    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;

      .page-title {
        font-weight: 600;
        font-size: 26px;
        padding-left: 10px !important;

        &.button-page-title {
          font-weight: 600;
          font-size: 18px;
          color: var(--ion-color-primary);
          display: flex;
          align-items: center;
          margin-top: 16px;
          padding-left: 0 !important;

          .button-page-icon {
            padding-right: 4px;
          }
        }
      }

      .img-page-title-row {
        // margin-left: 10px;
        display: flex;
        align-items: center;

        .title-image {
          height: 21px;
          width: 21px;
        }

        .title {
          font-size: 18px;
          line-height: 22px;
          margin-left: 1px;
        }
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }

        .summary-expand-toggle {
          text-align: center;

          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;

            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }

    .tbl-ctn .MuiTableContainer-root .MuiTable-root {
      background: var(--webkit-gradient-background-1);
      background: var(--linear-gradient-background-1);
    }

    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }
    }

    .txt-info {
      font-weight: bold;
      font-size: 13px;
    }

    .mob-fs-13 {
      font-weight: bold !important;
      font-size: 13px;
      text-transform: capitalize;
    }

    .mob-fs-14 {
      font-weight: bold !important;
      font-size: 14px;
      text-transform: capitalize;
    }

    .mob-fs-16 {
      font-weight: bold !important;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}

.competitions-menu-tabs {
  .competitions-menu-nav-link {
    .MuiButtonBase-root {
      border-bottom: unset;
    }
  }
  .MuiButtonBase-root {
    opacity: 1;
    border-bottom: 1px solid var(--ion-primary-border-color);
    padding: 6px 0;
    max-width: unset;

    &:last-child {
      border-bottom: 0 !important;
    }
    .MuiTab-wrapper {
      opacity: 0.6;
    }
    &.active {
      .MuiTab-wrapper {
        color: var(--ion-color-primary);
        opacity: 1;
      }
    }
  }
}
.tbl-ctn .MuiPaper-root {
  border-radius: 8px 8px 0 0;
}
.balance-history-tbl-ctn .tbl-ctn .MuiPaper-root {
  border-radius: 8px 8px 0 0;
}
.tbl-ctn .MuiTable-root {
  border-collapse: separate;
}
