.competitions-menu {
  height: 100%;
  background: var(--table-bg);
  margin: 10px;
  border-radius: 30px;
  box-shadow: 1px 1px 30px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  .eventTypes-menu-nav-link {
    display: flex;
    text-decoration: none;
    color: var(--ion-text-color);
    text-align: center;
    font-family: var(--headers-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 18px 0px;

    .MuiButton-root {
      color: var(--ion-text-color);
    }

    .logout-btn {
      color: var(--ion-color-primary);
      text-align: center;
      font-family: var(--headers-font-family);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.active-sidebar-link {
  color: #fff;
  border-radius: 35px;
  background: var(--ion-color-primary);
  padding: 5px;
}
.MuiButton-label {
  font-family: var(--headers-font-family) !important;
  font-weight: 600;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
